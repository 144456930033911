import {
  ImpressumModal,
  ImpressumModalProps,
  useCaseType,
} from '@softopus-io/rimac-ui-lib';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useImpressumQuery } from '@/api';

export const Impressum = () => {
  const { data } = useImpressumQuery();
  const [visible, setVisible] = useState(false);

  const impressumData = data?.impressum?.data?.attributes;

  const router = useRouter();

  useEffect(() => {
    if (window.location.hash && !visible) {
      const { pathname, search } = window.location;
      const newPath = pathname + search;
      router.replace(newPath, undefined, { shallow: true });
    }
  }, [router, visible]);

  useEffect(() => {
    const handleUrlChange = () => {
      if (window.location.hash.substring(1).includes('impressum')) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('hashchange', handleUrlChange);

    return () => {
      window.removeEventListener('hashchange', handleUrlChange);
    };
  }, []);

  const modalData: ImpressumModalProps = {
    heading: impressumData?.title || '',
    closeModal: () => setVisible(false),
    useCase: 'technology' as useCaseType,
    isModalOpen: visible,
    richText: impressumData?.text,
  };

  if (visible && data) return <ImpressumModal {...modalData} />;
};
